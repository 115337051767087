import "../Style/Footer.css";

const Footer = () => {
    return (
        <footer class="text-center p-3 bg-body-tertiary">
        <div>&copy; Established in 2017</div>
      </footer>
    );
};

export default Footer;
